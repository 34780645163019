/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ReactNode } from 'react';

import { NetfrontProductType } from '@netfront/common-library';
import { DBNetfrontServiceType, IProjectSetting } from '@netfront/gelada-identity-library';
import { ActionsIcon, AnnouncementIcon, AudioTabIcon, CalendarIcon, ClusterIcon, CodeIcon, CommentsIcon, ContentIcon, CustomFieldsIcon, DirectoryIcon, DiscountIcon, FilterIcon, FlaggingIcon, FormIcon, FormsIcon, GlossaryIcon, GroupAccessIcon, GroupRequestsIcon, HelpDocsIcon, HollowImageIcon, HollowVideoIcon, InsightsIcon, InvitationsIcon, InviteIcon, KeyTabIcon, LearningIcon, LibrariesIcon, MarketplaceIcon, MembersIcon, ModerationIcon, NavigationItemProps, NotesTabIcon, NotificationIcon, NotifyUsersIcon, OrdersIcon, PartnersIcon, QuestionnaireIcon, QuestionsIcon, ReportingIcon, ResourceIcon, ResourcesIcon, RolesIcon, SimulationIcon, SliderIcon, SocialIcon, StyleTabIcon, SubscriptionsIcon, SupportIcon, TagsIcon, TopicsIcon, TranscriptTabIcon, UnitsTabIcon, UserFlowIcon, UserIcon, UsersIcon } from '@netfront/ui-library';
import Link from 'next/link';
import { IReportOption } from 'types';

import { NetfrontServices, REPORT_OPTIONS } from './DashboardContext.constants';

const getFormattedReportOptions = ({ services, projectId, projectSettings }: { projectId: string; projectSettings: IProjectSetting; services?: DBNetfrontServiceType[];}): IReportOption[] => {

  const options: IReportOption[] =
    REPORT_OPTIONS.sort((a, b) => a.order - b.order)
    .reduce((acc, item) => {
      const { items, service, name, route, icon, setting } = item;
      const optionItems = items.sort((a, b) => a.order - b.order).map(({ route: itemRoute, name: itemName, projectId: itemProjectId, setting: itemSetting }) => ({
        id: itemRoute,
        name: itemName,
        value: itemRoute,
        isVisible: (
          (!itemSetting || projectSettings[itemSetting] as boolean) &&
          (!service || services?.includes(service as DBNetfrontServiceType)) &&
          (itemRoute !== 'export-logins-trials' || (projectId === itemProjectId))
        )  ?? false,
      })).filter(({ isVisible }) => isVisible);

      const reportOption = {
        id: route,
        isVisible: ((!service || services?.includes(service as DBNetfrontServiceType)) && (!setting || projectSettings[setting] as boolean)) ?? false,
        name,
        value: route,
        icon,
        subItems: optionItems,
      };

      if (optionItems.length > 0) {
        acc.push(reportOption);
      }
      return acc;
    }, [] as IReportOption[]);

    return options.filter(({ isVisible }) => isVisible);

};

const hasReportOptions = ({ services, projectId, projectSettings, route }: { projectId: string; projectSettings: IProjectSetting; route: string; services?: DBNetfrontServiceType[];}) => {
  const options = getFormattedReportOptions({ services, projectId, projectSettings });
  return options.some(({ id }) => id === route);
};


const getProductNavigationItems = ({
  dashboardUrl,
  baseUrl,
  hasGroupRequest = false,
  product,
  projectId,
}: {
  baseUrl: string;
  dashboardUrl: string;
  hasGroupRequest?: boolean;
  product: NetfrontProductType,
  projectId: string;
}): NavigationItemProps[] => {
  return [
    {
      icon: StyleTabIcon,
      label: 'Style and layout',
      id: 'style',
      isHidden: product === 'QUICKCODES',
      href: `${dashboardUrl}/style-and-layout`
    },
    {
      icon: KeyTabIcon,
      label: 'Install codes',
      id: 'integrations',
      isHidden: product === 'QUICKCODES',
      href: `${dashboardUrl}/integrations`
    },
    {
      icon: UnitsTabIcon,
      label: 'Reporting',
      id: 'reporting',
      href: `${dashboardUrl}/reporting`
    },
    {
      icon: SupportIcon,
      label: 'Support',
      id: 'support',
      href: `${baseUrl}/contact`
    },
    {
      icon: ResourcesIcon,
      label: 'Resources',
      id: 'resources',
      href: `${baseUrl}/docs`,
    },
    {
      icon: PartnersIcon,
      label: 'Users',
      id: 'users',
      wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user`}>{children}</Link>,
      subItems: [
        {
          icon: InvitationsIcon,
          label: 'Invitations',
          id: 'invitations',
          wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user/invitations`}>{children}</Link>,
        },
        {
          icon: GroupRequestsIcon,
          label: 'Group requests',
          id: 'group-requests',
          isHidden:  !hasGroupRequest,
          wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user/group-requests`}>{children}</Link>,
        },
      ]
    }
  ];
};


const getEkardoNavigationItems = ({
  backOfficeBaseUrl,
  baseUrl,
  isCustomBuild = false,
  projectSettings,
  projectId,
  services,
}: {
  backOfficeBaseUrl: string;
  baseUrl: string;
  isCustomBuild?: boolean;
  projectId: string;
  projectSettings: IProjectSetting;
  services: DBNetfrontServiceType[];
}) => {

  return [
    {
      id: 'content',
      href: `${baseUrl}/content`,
      icon: ContentIcon,
      label: 'Content',
      isHidden: 
        !(projectSettings['HAS_CONTENT_TYPE_LEARNING'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_RESOURCE'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_PAGE'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_NEWS'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_BLOG'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_QUESTIONNAIRE'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_EVENTS'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_HELP'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_CONSENT'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_CARTOON'] as boolean)
        && !(projectSettings['HAS_CONTENT_TYPE_ACTIVITY'] as boolean)
      ,
      subItems: [
        {
          id: 'learning',
          href: `${baseUrl}/content/learning`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_LEARNING'] as boolean),
          icon: LearningIcon,
          label: 'Learning',
        },
        {
          id: 'resource',
          href: `${baseUrl}/content/resource`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_RESOURCE'] as boolean),
          icon: ResourceIcon,
          label: 'Resources',
        },
        {
          id: 'page',
          href: `${baseUrl}/content/page`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_PAGE'] as boolean),
          icon: FormIcon,
          label: 'Page',
        },
        {
          id: 'news',
          href: `${baseUrl}/content/news`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_NEWS'] as boolean),
          icon: TranscriptTabIcon,
          label: 'News',
        },
        {
          id: 'blog',
          href: `${baseUrl}/content/blog`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_BLOG'] as boolean),
          icon: ResourceIcon,
          label: 'Blog',
        },
        {
          id: 'questionnaire',
          href: `${baseUrl}/content/questionnaire`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_QUESTIONNAIRE'] as boolean),
          icon: QuestionnaireIcon,
          label: 'Questionnaire',
        },
        {
          id: 'events',
          href: `${baseUrl}/content/events`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_EVENTS'] as boolean),
          icon: CalendarIcon,
          label: 'Events',
        },
        {
          id: 'help',
          href: `${baseUrl}/content/help`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_HELP'] as boolean),
          icon: HelpDocsIcon,
          label: 'Help',
        },
        {
          id: 'consent',
          href: `${baseUrl}/content/consent`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_CONSENT'] as boolean),
          icon: NotesTabIcon,
          label: 'Consent',
        },
        {
          id: 'cartoon',
          href: `${baseUrl}/content/cartoon`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_CARTOON'] as boolean),
          icon: UserIcon,
          label: 'Cartoon',
        },
        {
          id: 'activity',
          href: `${baseUrl}/content/activity`,
          isHidden: !(projectSettings['HAS_CONTENT_TYPE_ACTIVITY'] as boolean),
          icon: ActionsIcon,
          label: 'Activity',
        },
      ],
    },
    {
      id: 'library',
      href: `${baseUrl}/library`,
      icon: LibrariesIcon,
      label: 'Libraries',
      iaHidden: !(
        !(projectSettings['HAS_IMAGE_LIBRARY'] as boolean)
        && !(projectSettings['HAS_DOCUMENT_LIBRARY'] as boolean)
        && !(projectSettings['HAS_VIDEO_LIBRARY'] as boolean)
        && !(projectSettings['HAS_AUDIO_LIBRARY'] as boolean)
        && !(projectSettings['HAS_FORM_LIBRARY'] as boolean)
        && !(projectSettings['HAS_SLIDER_LIBRARY'] as boolean)
        && !(projectSettings['HAS_APP_LIBRARY'] as boolean)
        && !(projectSettings['HAS_TAG_LIBRARY'] as boolean)
        && !(projectSettings['HAS_DIRECTORY_LIBRARY'] as boolean)
        && !(projectSettings['HAS_INTERACTIVE_REGIONS_LIBRARY'] as boolean)
        && !(projectSettings['HAS_GLOSSARY_LIBRARY'] as boolean)
        && !(projectSettings['HAS_CHARACTER_LIBRARY'] as boolean)
        && !(projectSettings['HAS_CUSTOM_CSS_LIBRARY'] as boolean)
      ),
      subItems: [
        {
          id: 'image',
          href: `${baseUrl}/library/assets/images`,
          icon: HollowImageIcon,
          isHidden: !(projectSettings['HAS_IMAGE_LIBRARY'] as boolean),
          label: 'Image',
        },
        {
          id: 'document',
          href: `${baseUrl}/library/assets/documents`,
          icon: ResourcesIcon,
          isHidden: !(projectSettings['HAS_DOCUMENT_LIBRARY'] as boolean),
          label: 'Document',
        },
        {
          id: 'video',
          href: `${baseUrl}/library/assets/videos`,
          icon: HollowVideoIcon,
          isHidden: !(projectSettings['HAS_VIDEO_LIBRARY'] as boolean),
          label: 'Video',
        },
        {
          id: 'audio',
          href: `${baseUrl}/library/assets/audios`,
          icon: AudioTabIcon,
          isHidden: !(projectSettings['HAS_AUDIO_LIBRARY'] as boolean),
          label: 'Audio',
        },
        {
          id: 'form',
          href: `${baseUrl}/library/forms`,
          icon: FormsIcon,
          isHidden: !(projectSettings['HAS_FORM_LIBRARY'] as boolean),
          label: 'Forms',
        },
        {
          id: 'slider',
          href: `${backOfficeBaseUrl}/library/slider`,
          icon: SliderIcon,
          isHidden: !(projectSettings['HAS_SLIDER_LIBRARY'] as boolean),
          label: 'Slider',
        },
        {
          id: 'app',
          href: `${baseUrl}/library/apps`,
          icon: MarketplaceIcon,
          isHidden: isCustomBuild || !(projectSettings['HAS_APP_LIBRARY'] as boolean),
          label: 'App',
          subItems: [
            {
              id: 'project-apps',
              href: `${baseUrl}/library/apps/project-apps`,
              icon: MarketplaceIcon,
              label: 'Project apps',
            },
            {
              id: 'marketplace',
              href: `${baseUrl}/library/apps/marketplace`,
              icon: MarketplaceIcon,
              label: 'Marketplace',
            },
            {
              id: 'pending-app-approvals',
              href: `${baseUrl}/library/apps/pending-app-approvals`,
              icon: MarketplaceIcon,
              label: 'Pending approvals',
            },
          ],
        },
        {
          id: 'tags',
          href: `${baseUrl}/library/tags`,
          icon: TagsIcon,
          isHidden: !(projectSettings['HAS_TAG_LIBRARY'] as boolean),
          label: 'Tags',
        },
        {
          id: 'directory',
          href: `${baseUrl}/library/directory`,
          icon: DirectoryIcon,
          isHidden: !(projectSettings['HAS_DIRECTORY_LIBRARY'] as boolean),
          label: 'Directory',
        },
        {
          id: 'interactive-regions',
          href: `${backOfficeBaseUrl}/library/imageMap`,
          icon: ClusterIcon,
          isHidden: !(projectSettings['HAS_INTERACTIVE_REGIONS_LIBRARY'] as boolean),
          label: 'Interactive regions',
        },
        {
          id: 'glossary',
          href: `${baseUrl}/library/glossary`,
          icon: GlossaryIcon,
          isHidden: !(projectSettings['HAS_GLOSSARY_LIBRARY'] as boolean),
          label: 'Glossary',
        },
        {
          id: 'character',
          href: `${baseUrl}/library/character`,
          isHidden: !(projectSettings['HAS_CHARACTER_LIBRARY'] as boolean),
          icon: UserIcon,
          label: 'Character',
        },
      ],
    },
    {
      id: 'users',
      wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user`}>{children}</Link>,
      icon: PartnersIcon,
      label: 'Users',
      subItems: [
        {
          icon: InvitationsIcon,
          label: 'Invitations',
          id: 'invitations',
          wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user/invitations`}>{children}</Link>,
        },
        {
          icon: GroupRequestsIcon,
          label: 'Group requests',
          id: 'group-requests',
          isHidden: !(projectSettings?.['HAS_GROUP_REQUEST'] as boolean),
          wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user/group-requests`}>{children}</Link>,
        },
        {
          icon: UsersIcon,
          label: 'Groups',
          id: 'groups',
          wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user/groups`}>{children}</Link>,
        },
        {
          icon: RolesIcon,
          label: 'Roles',
          id: 'roles',
          wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user/roles`}>{children}</Link>,
        },
        {
          icon: CustomFieldsIcon,
          label: 'Custom fields',
          id: 'custom-fields',
          wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user/custom-fields`}>{children}</Link>,
        },
        {
          icon: CodeIcon,
          label: 'Placeholders',
          id: 'placeholders',
          wrapperComponent: ({ children }: { children: ReactNode}) => <Link href={`/project/${projectId}/user/placeholders`}>{children}</Link>,
        },
      ],
    },
    {
      id: 'subscriptions',
      isHidden: !((services?.includes(NetfrontServices.MURIQUI as DBNetfrontServiceType) && services.includes(NetfrontServices.VERVET as DBNetfrontServiceType)) ?? false),
      href: `${baseUrl}/subscriptions`,
      icon: SubscriptionsIcon,
      label: 'Subscriptions',
      subItems: [
        {
          id: 'orders',
          href: `${baseUrl}/subscriptions/orders`,
          icon: OrdersIcon,
          label: 'Orders',
        },
        {
          id: 'discount-codes',
          href: `${baseUrl}/subscriptions/discounts`,
          icon: DiscountIcon,
          label: 'Discount codes',
        },
      ],
    },
    {
      id: 'social',
      href: `${baseUrl}/social`,
      icon: SocialIcon,
      isHidden: !(services?.includes(`${NetfrontServices.BONOBO as DBNetfrontServiceType}`) ?? false),
      label: 'Social',
      subItems: [
        {
          id: 'moderation',
          href: `${baseUrl}/social/moderation`,
          isHidden: !(projectSettings['HAS_MODERATION_MANAGEMENT'] as boolean),
          icon: ModerationIcon,
          label: 'Moderation',
        },
        {
          id: 'topics',
          href: `${baseUrl}/social/topics`,
          icon: TopicsIcon,
          label: 'Topics',
          isHidden: !(projectSettings?.['HAS_TOPIC'] as boolean),
        },
        {
          id: 'communities',
          href: `${baseUrl}/social/communities`,
          icon: GroupRequestsIcon,
          label: 'Communities',
          isHidden: !(projectSettings?.['HAS_COMMUNITY'] as boolean),
        },
        {
          id: 'events',
          href: `${baseUrl}/social/events`,
          icon: CalendarIcon,
          label: 'Events',
          isHidden: !(projectSettings?.['HAS_EVENT_COMMUNITIES'] as boolean),
        },
        {
          id: 'users',
          href: `${baseUrl}/social/users`,
          icon: MembersIcon,
          label: 'Users',
          isHidden: !(projectSettings?.['HAS_USER_MANAGEMENT'] as boolean),
        },
        {
          id: 'language-filter',
          href: `${baseUrl}/social/language-filter`,
          icon: FlaggingIcon,
          label: 'Language filters',
          isHidden: !(projectSettings?.['HAS_LANGUAGE_FILTER'] as boolean),
        },
      ]
    },
    {
      id: 'insights',
      href: `${baseUrl}/insight`,
      icon: InsightsIcon,
      label: 'Insight',
      isHidden: !(projectSettings['HAS_INSIGHTS'] as boolean),
      subItems: [],
    },
    {
      id: 'progress-tracking',
      href: `${baseUrl}/progress-tracking`,
      icon: UserFlowIcon,
      isHidden: !(projectSettings?.['HAS_PROGRESS_TRACKER'] as boolean),
      label: 'Progress tracking',
      subItems: [],
    },
    {
      id: 'reporting',
      href: `${baseUrl}/reporting`,
      icon: ReportingIcon,
      label: 'Reporting',
      subItems: [
        {
          id: 'reporting-content',
          href: `${baseUrl}/reporting/reporting-content`,
          icon: ContentIcon,
          isHidden: !(
            (services?.includes(NetfrontServices.EKARDO as DBNetfrontServiceType)
            && hasReportOptions({ 
              projectId, 
              projectSettings, 
              services, 
              route: 'reporting-content'
            })
          ) ?? false),
          label: 'Content',
        },
        {
          id: 'reporting-feedback',
          href: `${baseUrl}/reporting/reporting-feedback`,
          icon: CommentsIcon,
          isHidden: !(
            projectSettings['HAS_FEEDBACK_REPORT'] as boolean
            &&
            hasReportOptions({ 
              projectId, 
              projectSettings,
              services, 
              route: 'reporting-feedback'
            })
          ),
          label: 'Feedback',
        },
        {
          id: 'reporting-notifications',
          href: `${baseUrl}/reporting/reporting-notifications`,
          icon: NotifyUsersIcon,
          isHidden: !(
            projectSettings['HAS_NOTIFICATION_REPORT'] as boolean
            &&
            hasReportOptions({ 
              projectId, 
              projectSettings,
              services, 
              route: 'reporting-notifications'
            })),
          label: 'Notifications',
        },
        {
          id: 'reporting-questionnaires',
          href: `${baseUrl}/reporting/reporting-questionnaires`,
          icon: QuestionsIcon,
          isHidden: !(
            projectSettings['HAS_QUESTIONNAIRE_REPORT'] as boolean
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings,
                services, 
                route: 'reporting-questionnaires'
              })
          ),
          label: 'Questionnaires',
        },
        {
          id: 'reporting-social',
          href: `${baseUrl}/reporting/reporting-social`,
          icon: SocialIcon,
          isHidden: !(
            projectSettings['HAS_SOCIAL_REPORTS'] as boolean
            &&
            hasReportOptions({ 
              projectId, 
              projectSettings,
              services, 
              route: 'reporting-social'
            })
          ),
          label: 'Social',
        },
        {
          id: 'reporting-user-entry',
          href: `${baseUrl}/reporting/reporting-user-entry`,
          icon: FormIcon,
          isHidden: !((
            services?.includes(NetfrontServices.TRIPLE_E as DBNetfrontServiceType)
            &&
            hasReportOptions({ 
              projectId, 
              projectSettings,
              services, 
              route: 'reporting-user-entry'
            })
          ) ?? false),
          label: 'User entry',
        },
        {
          id: 'reporting-users',
          href: `${baseUrl}/reporting/reporting-users`,
          isHidden: !hasReportOptions({ 
            projectId, 
            projectSettings,
            services, 
            route: 'reporting-users'
          }),
          icon: PartnersIcon,
          label: 'Users',
        },
        {
          id: 'reporting-custom-report',
          href: `${baseUrl}/reporting/reporting-custom-report`,
          icon: CustomFieldsIcon,
          isHidden: !(
            projectSettings['HAS_CUSTOM_REPORT'] as boolean 
            && 
            hasReportOptions({ 
              projectId, 
              projectSettings,
              services, 
              route: 'reporting-custom-report'
            })
          ),
          label: 'Custom report',
        },
        
      ],
    },
    {
      id: 'notifications',
      isHidden: !(services?.includes(`${NetfrontServices.HOWLER as DBNetfrontServiceType}`) ?? services?.includes(`${NetfrontServices.CAPUCHIN  as DBNetfrontServiceType}`) ?? false),
      href: `${baseUrl}/notifications`,
      icon: NotificationIcon,
      label: 'Notifications',
      subItems: [
        {
          href: `${baseUrl}/notifications/scheduler`,
          icon: CalendarIcon,
          id: 'scheduler',
          isHidden: !(projectSettings?.['HAS_SCHEDULER'] as boolean),
          label: 'Scheduler',
          subItems: [
            {
              id: 'scenarios',
              href: `${baseUrl}/notifications/scheduler/scenarios`,
              icon: FilterIcon,
              isHidden: !(services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false),
              label: 'Scenarios',
              subItems: [
                {
                  id: 'scenario-users',
                  href: `${baseUrl}/notifications/scheduler/scenarios/users`,
                  icon: UserIcon,
                  isHidden: !(services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false),
                  label: 'Users',
                  
                },
                {
                  id: 'scenario-invitations',
                  href: `${baseUrl}/notifications/scheduler/scenarios/invitations`,
                  icon: InviteIcon,
                  isHidden: !(services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false),
                  label: 'Invitations',
                  
                },
              ]
            },
            {
              id: 'simulator',
              href: `${baseUrl}/notifications/scheduler/simulator`,
              icon: SimulationIcon,
              isHidden: !(services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false),
              label: 'Simulator',
              
            },
            {
              id: 'test-mode',
              href: `${baseUrl}/notifications/scheduler/test-mode`,
              icon: UserFlowIcon,
              isHidden: !(services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false),
              label: 'Test mode',
              
            },
          ]
        },
        {
          id: 'notify',
          href: `${baseUrl}/notifications/notify`,
          icon: NotifyUsersIcon,
          isHidden: !(projectSettings?.['HAS_NOTIFY_USERS'] as boolean),
          label: 'Notify users',
        },
        {
          id: 'templates',
          icon: TranscriptTabIcon,
          href: `${backOfficeBaseUrl}/notifications/notification-templates`,
          isInternal: false,
          isHidden: !(services?.includes(NetfrontServices.HOWLER as DBNetfrontServiceType) ?? false),
          label: 'Templates',
        },
        {
          id: 'announcements',
          icon: AnnouncementIcon,
          href: `${baseUrl}/notifications/announcements`,
          isHidden: !(projectSettings?.['HAS_ANNOUNCEMENTS'] as boolean),
          label: 'Announcements',
        },
      ]
    },
    {
      id: 'group-access',
      isHidden: !(services?.includes(`${NetfrontServices.VERVET as DBNetfrontServiceType}`) ?? false),
      href: `${baseUrl}/group-access`,
      icon: GroupAccessIcon,
      label: 'Group access',
      subItems: [],
    },
  ];
}


export { getEkardoNavigationItems, getProductNavigationItems };
