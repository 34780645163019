import { isObjectsEqualIgnoringKeys } from './isObjectsEqual';

type ActionType = 'add' | 'addRemove' | 'remove' | 'update';

function manageObjectInArray<T extends object>(
  array: T[],
  object: T,
  action: ActionType = 'update',
  newObject?: T,
  keysToIgnore: (keyof T)[] = [],
): T[] {
  const newArray: T[] = [...array]; // Create a shallow copy of the original array
  const index = newArray.findIndex((item) => isObjectsEqualIgnoringKeys(item, object, keysToIgnore));

  if (index === -1 && ['add', 'update', 'addRemove'].includes(action)) {
    // Object doesn't exist in the array, so add it to the new array
    newArray.push(newObject ?? object);
  } else if (index !== -1 && ['remove', 'addRemove'].includes(action)) {
    // Object exists in the array, and action is "remove," so remove it from the new array
    newArray.splice(index, 1);
  } else if (index !== -1 && action === 'update') {
    // Object exists in the array, and action is "update," so update it in the new array
    newArray[index] = newObject ?? object;
  }

  return newArray;
}

export { manageObjectInArray };
