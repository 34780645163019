import { NetfrontProductType } from '@netfront/common-library';

export type RegisterCustomFieldFeature = 'Plan' | 'Translate' | 'Speech' | 'PDF';
export type RegisterCustomFieldTier = 'Platinum' | 'Advanced' | 'Starter' | 'Free' | 'Paid';

export interface IAddUserToEmailSubscriptionParams {
  customFields?: Partial<Record<RegisterCustomFieldFeature, RegisterCustomFieldTier>>;
  emailAddress: string;
  name: string;
  onError?: (statusText: string) => void;
  onSuccess?: () => void;
  product: NetfrontProductType;
}

export const getProductDetails = (product: NetfrontProductType) => {
  const productDetailsMap = {
    KANZI: {
      listId: String(process.env.REACT_APP_KANZI_CREATE_SEND_LIST_ID_REGISTERED),
      apiKey: String(process.env.REACT_APP_KANZI_CREATE_SEND_API_KEY),
    },
    QUICKCODES: {
      listId: String(process.env.REACT_APP_QUICKCODES_CREATE_SEND_LIST_ID_REGISTERED),
      apiKey: String(process.env.REACT_APP_QUICKCODES_CREATE_SEND_API_KEY),
    },
    EKARDO: {
      listId: '',
      apiKey: '',
    },
  };

  return productDetailsMap[product];
};

export const addUserToEmailSubscription = ({
  emailAddress,
  name,
  customFields,
  onError,
  onSuccess,
  product,
}: IAddUserToEmailSubscriptionParams) => {
  if (product === 'EKARDO') {
    return;
  }

  const { listId, apiKey } = getProductDetails(product);

  const http = new XMLHttpRequest();
  const url = 'https://00y089pbdi.execute-api.ap-southeast-2.amazonaws.com/default/email-subscriber';

  http.open('POST', url);
  http.send(
    JSON.stringify({
      SendApiKey: apiKey,
      SendListId: listId,
      emailAddress,
      name,
      customFields,
    }),
  );

  http.onreadystatechange = () => {
    if (http.readyState !== 4) {
      return false;
    }

    if (http.status !== 200) {
      if (onError) {
        onError(http.statusText);
      }

      return false;
    }

    if (onSuccess) {
      onSuccess();
    }

    return true;
  };
};
