import { gql } from '@apollo/client';

export const CREATE_PLACEHOLDER = gql`
  mutation createPlaceholder(
    $projectId: String!,
    $key: String!,
    $value: String!,
  ) {
    placeholder {
      add(
        projectId: $projectId,
        key: $key,
        value: $value,
      ) {
        id 
        key
        value
      }
    }
  }
`;

