
function isObjectsEqual<T extends object>(
  obj1: T,
  obj2: T): boolean {
  // Convert objects to JSON strings and compare
  const string1 = JSON.stringify(obj1);
  const string2 = JSON.stringify(obj2);
  return string1 === string2;
};

function isObjectsEqualIgnoringKeys<T extends object>(
  obj1: T,
  obj2: T,
  keysToIgnore: (keyof T)[] = []
): boolean {
  const filteredObj1: Partial<T> = Object.keys(obj1).reduce((acc, key) => {
    const typedKey = key as keyof T;
    if (!keysToIgnore.includes(typedKey)) {
      acc[typedKey] = obj1[typedKey];
    }
    return acc;
  }, {} as Partial<T>);

  const filteredObj2: Partial<T> = Object.keys(obj2).reduce((acc, key) => {
    const typedKey = key as keyof T;
    if (!keysToIgnore.includes(typedKey)) {
      acc[typedKey] = obj2[typedKey];
    }
    return acc;
  }, {} as Partial<T>);

  return JSON.stringify(filteredObj1) === JSON.stringify(filteredObj2);
}

export { isObjectsEqual, isObjectsEqualIgnoringKeys };
