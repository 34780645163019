import { useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { isSecureCookie, useCookie } from '@netfront/common-library';
import { useToast } from '@netfront/ekardo-content-library';
import { DEFAULT_STORAGE_EXPIRY_OPTIONS, useDomain, useRefreshToken } from '@netfront/gelada-identity-library';

const useUpdateAccessToken = () => {
  const { createAccessTokenCookie, createRefreshTokenCookie } = useCookie();
  const { handleToastError } = useToast();
  const { getDomain, isDomainReady } = useDomain();
  const dynamicCallBackRef = useRef<((accessToken: string) => void) | null>(null);
  const [domain, setDomain] = useState<string | null>();
  const [isSecure, setIsSecure] = useState(false);

  const { handleRefreshToken, isLoading: isHandleRefreshTokenLoading = false } = useRefreshToken({
    onCompleted: ({ accessToken, refreshToken }) => {
      if (accessToken) {
        // The createXXXCookie functions all validate that a cookie doesn't exceed the maximum cookie size
        // We only expect the access token to potentially exceed the maximum cookie size hence this is the
        // only function that will be wrapped inside a try/catch block

        try {
          createAccessTokenCookie({
            optionalCookieAttributesInput: {
              domain: domain ?? '',
              secure: isSecure,
              expiryOptions: {
                storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
              }
            },
            value: accessToken,
          });
        } catch (error) {
          handleToastError({
            error: error as Error,
            shouldUseFriendlyErrorMessage: true,
          });

          return;
        } finally {
          if (dynamicCallBackRef.current) {
            dynamicCallBackRef.current(accessToken);
          }
        }
      }

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain: domain ?? '',
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const updateRefreshToken = (callBack: (accessToken: string) => void) => {
    dynamicCallBackRef.current = callBack;
    void handleRefreshToken();
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());
    setIsSecure(isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return {
    updateRefreshToken,
    isLoading: isHandleRefreshTokenLoading,
  };
};

export { useUpdateAccessToken };
