import { gql } from '@apollo/client';

const UPDATE_GELADA_PROJECT_MUTATION = gql`
  mutation updateGeladaProject(
    $backOfficeEndpoint: String
    $description: String
    $name: String!
    $projectId: String!
    $url: String
    $services: [ENetfrontService]!
    $logo: CreateAssetBaseInputType
  ) {
    project {
      updateGeladaProject: updateProject(
        project: {
          backOfficeEndpoint: $backOfficeEndpoint
          description: $description
          name: $name
          projectId: $projectId
          services: $services
          url: $url
          logo: $logo
        }
      ) {
        logo {
          presignedUrl
          presignedPutUrl
        }
        id
        name
        projectAdministrators {
          firstname
          lastname
        }
        isFavourite
      }
    }
  }
`;


export { UPDATE_GELADA_PROJECT_MUTATION };
