import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import { INTERNAL_URLS } from '../constants';

const useLoginRedirect = () => {
  const { push } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const handleLoginRedirect = ({
    customRedirectUrl = '',
    isCustomBuild = false,
    isUserAProjectAdmin = false,
    projectWebsiteUrl,
    redirectAfterLoginUrl,
    redirectIfSingularOrgProjectUrl,
  } : {
    customRedirectUrl?: string;
    isCustomBuild?: boolean;
    isUserAProjectAdmin?: boolean;
    projectWebsiteUrl?: string;
    redirectAfterLoginUrl?: string;
    redirectIfSingularOrgProjectUrl?: string;
  }) => {
    let redirectUrl = redirectAfterLoginUrl ?? '';

    if (!isCustomBuild && customRedirectUrl) {
      redirectUrl = customRedirectUrl;
    } else {
      if (!isCustomBuild) {
        // if a project is not a custom build then redirect to a dashboard
        // if a user only has 1 org and 1 project redirect to project dashboard within product
        if (redirectIfSingularOrgProjectUrl) {
          redirectUrl = redirectIfSingularOrgProjectUrl;
        } else {
          // redirect to dashboard
          redirectUrl = INTERNAL_URLS.dashboard;
        }
      } else if (isUserAProjectAdmin) {
        // if project is a custom build and user is a project admin
        redirectUrl = redirectAfterLoginUrl ?? '';
      } else if (projectWebsiteUrl) {
        // if project is a custom build, user is not a project admin & there is a website url present
        redirectUrl = projectWebsiteUrl;
      } else {
        // if project is a custom build, user is not a project admin & there is not a website url present
        handleToastCustomError({ message: 'It seems this project has not been configured, please contact an administrator.' });
        return;
      }
    }

    

    push(redirectUrl).catch((error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    });
  }
  
  return { 
    handleLoginRedirect,
  };

};

export { useLoginRedirect };
