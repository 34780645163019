import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IGeladaProject, ProjectSettingType, useUpdateSettings } from '@netfront/gelada-identity-library';
import { FormFieldProps } from '@netfront/ui-library';
import { useCreateGeladaProject, useToast, useUpdateGeladaProject } from 'hooks';
import { IProjectLogoType } from 'types';
import { handleImageUpload } from 'utils';


const useUpsertProject = ({ onCreate, onUpdate }: { onCreate: (returnedProject: IGeladaProject) => void; onUpdate: (returnedProject: IGeladaProject) => void }) => {
  const settingsVariables = useRef<{value :FormFieldProps}>({value: {}});
  const uploadedLogoRef =  useRef<{value: File | undefined}>({ value: undefined });
  const projectIdRef =  useRef<{value: string | undefined}>({ value: undefined });
  const projectRef =  useRef<{value: IGeladaProject | undefined}>({ value: undefined });
  const isProductRef =  useRef<{value: boolean }>({ value: false });

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleUpdateSettings, isLoading: isUpdatesettingsLoading = false } = useUpdateSettings({
    onCompleted: () => {

      if (projectIdRef.current.value) {
        onUpdate(projectRef.current.value as IGeladaProject);
      } else {
        onCreate(projectRef.current.value as IGeladaProject);
      }
      
    },
    onError: handleGetError,
  });

  const handleSaveSettings = (projectId: string) => {
    const settings = Object.keys(settingsVariables.current.value).map((key) => ({
        type: key as ProjectSettingType,
        value: settingsVariables.current.value[key],
        projectId,
    }));

    void handleUpdateSettings({ settings });
  };

  const { handleCreateGeladaProject, isLoading: isCreateProjectLoading = false } = useCreateGeladaProject({
    onCompletedAsync: async ({ project: returnedProject }: { project: IGeladaProject }) => {
      projectRef.current.value = returnedProject;
      if (uploadedLogoRef.current.value) {
        await handleImageUpload({
          logo: returnedProject.logo,
          uploadedFile: uploadedLogoRef.current.value,
          callBack: () => {
            if (!isProductRef.current.value) {
              handleSaveSettings(returnedProject.id);
            } else {
              onCreate(returnedProject);
            }
          },
        });
      } else {
        if (!isProductRef.current.value) {
          handleSaveSettings(returnedProject.id);
        } else {
          onCreate(returnedProject);
        }
      }

      handleToastSuccess({
        message: `Project ${returnedProject.name} created successfully`,
      });
    },
    onError: handleGetError,
  });

  const { handleUpdateGeladaProject, isLoading: isUpdateProjectLoading = false } = useUpdateGeladaProject({
    onCompletedAsync: async ({ project: returnedProject }: { project: IGeladaProject }) => {
      projectRef.current.value = returnedProject;
      if (uploadedLogoRef.current.value) {
        await handleImageUpload({
          logo: returnedProject.logo,
          uploadedFile: uploadedLogoRef.current.value,
          callBack: () => {
            if (!isProductRef.current.value) {
              handleSaveSettings(returnedProject.id);
            } else { 
              onUpdate(returnedProject);
            }
          },
        });
      } else {
        if (!isProductRef.current.value) {
          handleSaveSettings(returnedProject.id);
        } else {
          onUpdate(returnedProject);
        }
      }

      handleToastSuccess({
        message: `Project ${returnedProject.name} updated successfully`,
      });
    },
    onError: handleGetError,
  });

  const getCommonUpsertProjectConfig = (item: FormFieldProps, uploadedFile?: File) => {
    const { description, name, services } = item;
    const { type, name: fileName, size} = uploadedFile ?? {};
    return {
      name,
      description: String(description),
      services,
      logo: uploadedFile
        ? {
            contentType: String(type),
            fileName: String(fileName),
            fileSizeInBytes: Number(size),
            type: 'PROJECT_LOGO' as IProjectLogoType,
          }
        : undefined,
    };
  };
  

  const handleUpsertProject = ({ 
    isProduct = false,
    organisationId,
    projectId,
    uploadedFile,
    variables, 
  }: {
    isProduct: boolean;
    organisationId: number;
    projectId?: string;
    uploadedFile?: File;
    variables: {
      projectVariables: FormFieldProps;
      settings: FormFieldProps;
    }; 
  }) => {

    const { projectVariables, settings } = variables;

    const { isCustomBuild, key } = projectVariables;

    settingsVariables.current.value = settings;
    uploadedLogoRef.current.value = uploadedFile;
    projectIdRef.current.value = projectId;
    isProductRef.current.value = isProduct;

    if (projectId) {
      void handleUpdateGeladaProject({
        ...getCommonUpsertProjectConfig(projectVariables, uploadedFile),
        projectId: String(projectId),
      });
    } else {
      void handleCreateGeladaProject({
        ...getCommonUpsertProjectConfig(projectVariables, uploadedFile),
        isCustomBuild,
        key: key,
        organisationId: Number(organisationId),
      });
    }
  };

  return {
    isLoading: isCreateProjectLoading || isUpdateProjectLoading || isUpdatesettingsLoading,
    handleUpsertProject,
  };
};
export { useUpsertProject };
