import { ApolloQueryResult } from '@apollo/client';
import { useHowlerLazyQuery } from 'hooks';

import { SEARCH_PAGINATED_PLACEHOLDERS_QUERY } from './useSearchPaginatedPlaceholders.graphql';
import {
  ISearchPaginatedPlaceholdersQueryGraphQLResponse,
  ISearchPaginatedPlaceholdersQueryVariables,
  IHandleFetchMorePaginatedPlaceholdersParams,
  IHandleSearchPaginatedPlaceholdersParams,
  IUseSearchPaginatedPlaceholders,
  IUseSearchPaginatedPlaceholdersOptions,
} from './useSearchPaginatedPlaceholders.interfaces';

const useSearchPaginatedPlaceholders = (options?: IUseSearchPaginatedPlaceholdersOptions): IUseSearchPaginatedPlaceholders => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseSearchPaginatedPlaceholdersOptions);

  const [executeSearchPaginatedPlaceholders, { fetchMore, loading: isLoading }] = useHowlerLazyQuery<
  ISearchPaginatedPlaceholdersQueryGraphQLResponse,
  ISearchPaginatedPlaceholdersQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          placeholder: { list: placeholders },
        } = data;

        void onCompleted({
          placeholders,
        });
      },
      onError,
    },
    query: query ?? SEARCH_PAGINATED_PLACEHOLDERS_QUERY,
    token,
  });

  const handleFetchMorePaginatedPlaceholders = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedPlaceholdersParams): Promise<ApolloQueryResult<ISearchPaginatedPlaceholdersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          placeholder: { list: newlyFetchedPlaceholdersConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedPlaceholdersConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          placeholder: { list: previouslyFetchedPlaceholdersConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedPlaceholdersConnection;

        return {
          ...previousQueryResult,
          placeholder: {
            ...previouslyFetchedPlaceholdersConnection,
            list: {
              ...newlyFetchedPlaceholdersConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleSearchPaginatedPlaceholders = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleSearchPaginatedPlaceholdersParams) => {
    void executeSearchPaginatedPlaceholders({
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedPlaceholders,
    handleSearchPaginatedPlaceholders,
    isLoading,
  };
};

export { useSearchPaginatedPlaceholders };
