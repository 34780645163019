import { createContext } from 'react';

import { NavigationItemProps } from '@netfront/ui-library';

export interface IDashboardContext {
  getNavigationItems: () => NavigationItemProps[];
  navigationItems: NavigationItemProps[];
  organisationNavigationItems: NavigationItemProps[];
}

export const DashboardContext = createContext<IDashboardContext>({
  getNavigationItems: () => [],
  navigationItems: [],
  organisationNavigationItems: [],
});

// eslint-disable-next-line import/no-default-export
export default DashboardContext;
