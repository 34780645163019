import 'backpack.css';

import '@netfront/ekardo-content-library/dist/esm/css/index.css';

import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';
import '@netfront/ui-library/dist/css/typography.css';

import '../styles/components.css';
import '../styles/fonts.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

import { useEffect, useState } from 'react';

import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useGetProduct, useUser } from '@netfront/gelada-identity-library';
import { ErrorBoundary } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import LogRocket from 'logrocket';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import { INTERNAL_URLS } from '../constants';
import { PermissionProvider, CachingEntitiesProvider, UserProvider, DashboardProvider } from '../context';

function App({ Component, pageProps }: AppProps) {
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, trackUser } = useGoogleAnalytics();
  const { getGoogleAdwordsConversionId, getGoogleAnalyticsMeasurementId, getProduct } = useGetProduct();
  const { events } = useRouter();
  const { getUser } = useUser();

  const [gtagConfigParams, setGtagConfigParams] = useState<ReturnType<typeof omitBy>>();

  const product = getProduct();
  const googleAdwordsConversionId = getGoogleAdwordsConversionId(product) ?? '';
  const googleAnalyticsMeasurementId = getGoogleAnalyticsMeasurementId(product) ?? '';
  const user = getUser();

  if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    LogRocket.init('sfzpuh/identity-c5p55');
    if (!isEmpty(user)) {
      const {
        id: userId,
        firstName,
        lastName,
        email,
      } = user as unknown as { email: string; firstName: string; id: number; lastName: string };
      LogRocket.identify(String(userId), {
        name: `${firstName} ${lastName}`,
        email: email,
      });
    }
  }

  useEffect(() => {
    if (gtagConfigParams) {
      return;
    }

    if (!(googleAnalyticsMeasurementId && user)) {
      return;
    }

    setGtagConfigParams(() =>
      omitBy(
        {
          debug_mode: isGoogleAnalyticsDebugMode ? true : undefined,
          user_id: String(user.id),
        },
        isNil,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAnalyticsMeasurementId, gtagConfigParams, user]);

  useEffect(() => {
    if (!(googleAnalyticsMeasurementId && gtagConfigParams && user)) {
      return;
    }

    const handleRouteChange = (url: string) => {
      trackPageView(url);
      trackUser();
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAnalyticsMeasurementId, gtagConfigParams, user]);

  return (
    <>
      <Head>
        {(googleAdwordsConversionId || (googleAnalyticsMeasurementId && gtagConfigParams)) && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){ dataLayer.push(arguments); }
              gtag('js', new Date());
            `,
              }}
            />
          </>
        )}

        {googleAdwordsConversionId && (
          <>
            <script src={`https://www.googletagmanager.com/gtag/js?id=${googleAdwordsConversionId}`} async />

            <script
              dangerouslySetInnerHTML={{
                __html: `
                gtag('config', '${googleAdwordsConversionId}', { 'allow_enhanced_conversions': true });
              `,
              }}
            />
          </>
        )}

        {googleAnalyticsMeasurementId && gtagConfigParams && (
          <>
            <script src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMeasurementId}`} async />

            <script
              dangerouslySetInnerHTML={{
                __html: `
                gtag('config', '${googleAnalyticsMeasurementId}'${
                  Object.keys(gtagConfigParams).length ? `, ${JSON.stringify(gtagConfigParams)}` : ''
                });
              `,
              }}
            />
          </>
        )}
      </Head>

      <CachingEntitiesProvider>
        <div id="root">
          <ErrorBoundary dashboardUrl={INTERNAL_URLS.dashboard}>
            <UserProvider>
              <DashboardProvider>
                <PermissionProvider>
                  <Toaster
                    position="top-center"
                    reverseOrder={false}
                    toastOptions={{
                      duration: 3000,
                    }}
                  />

                  <Component {...pageProps} />
                </PermissionProvider>
              </DashboardProvider>
            </UserProvider>
          </ErrorBoundary>
        </div>
      </CachingEntitiesProvider>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
