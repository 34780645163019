import { ContentIcon, CommentsIcon, NotifyUsersIcon, QuestionsIcon, SocialIcon, FormIcon, PartnersIcon, CustomFieldsIcon } from "@netfront/ui-library";
import { IReportOptionConstant } from "types";

const NetfrontServices = {
  BONOBO: 'BONOBO',
  BONOBO_MANAGEMENT: 'BONOBO_MANAGEMENT',
  EKARDO: 'EKARDO',
  HOWLER: 'HOWLER',
  MURIQUI: 'MURIQUI',
  VERVET: 'VERVET',
  CAPUCHIN: 'CAPUCHIN',
  TRIPLE_E: 'TRIPLE_E',
};

const OURFUTURES_PROJECT_ID = 'Gelada-Project-6-531ecc29-b44f-4234-a91a-3d11be5cb63c';

const REPORT_OPTIONS: IReportOptionConstant[] = [
  {
    order: 1,
    name: 'Content',
    route: 'reporting-content',
    icon: ContentIcon,
    items: [
      {
        order: 1,
        route: 'export-all-module-page-completions',
        name: 'All module page completions',
        setting: 'HAS_PAGE_COMPLETION_REPORT',
      },
      {
        order: 2,
        route: 'export-all-module-pages',
        name: 'All module progress',
        setting: 'HAS_MODULE_PROGRESSION_REPORTS',
      },
      {
        order: 3,
        route: 'export-bookmarks',
        name: 'Bookmarks',
        setting: 'HAS_BOOKMARKS_REPORT',
      },
      {
        order: 4,
        route: 'export-module-page-completions',
        name: 'Module page completions',
        setting: 'HAS_PAGE_COMPLETION_REPORT',
      },
      {
        order: 5,
        route: 'export-module-pages',
        name: 'Module progress',
        setting: 'HAS_MODULE_PROGRESSION_REPORTS',
      },
      
      {
        order: 6,
        route: 'export-pages',
        name: 'Pages loaded',
        setting: 'HAS_PAGE_LOADED_REPORT',
      },
    ],
    service: 'EKARDO',
  },
  {
    order: 2,
    name: 'Feedback',
    icon: CommentsIcon,
    route: 'reporting-feedback',
    items: [
      {
        order: 1,
        route: 'export-feedback',
        name: 'General',
        setting: 'HAS_FEEDBACK_REPORT',
      },
    ],
    service: 'EKARDO',
    setting: 'HAS_FEEDBACK_REPORT',
  },
  {
    order: 3,
    name: 'Notifications',
    icon: NotifyUsersIcon,
    route: 'reporting-notifications',
    items: [
      {
        order: 1,
        route: 'export-notifications',
        name: 'General',
        setting: 'HAS_NOTIFICATION_REPORT',
      },
    ],
    service: 'HOWLER',
    setting: 'HAS_NOTIFICATION_REPORT',
  },
  {
    order: 4,
    name: 'Questionnaires',
    icon: QuestionsIcon,
    route: 'reporting-questionnaires',
    items: [
      {
        order: 1,
        route: 'export-questionnaires',
        name: 'User responses',
      },
    ],
    service: 'EKARDO',
    setting: 'HAS_QUESTIONNAIRE_REPORT',
  },
  {
    order: 5,
    name: 'Social',
    icon: SocialIcon,
    route: 'reporting-social',
    items: [
      {
        order: 1,
        route: 'export-comment-logs',
        name: 'Comment Logs',
      },
      {
        order: 2,
        route: 'export-comments',
        name: 'Comments',
      },
      {
        order: 3,
        route: 'export-post-logs',
        name: 'Post Logs',
      },
      {
        order: 4,
        route: 'export-posts',
        name: 'Posts',
      },
      {
        order: 5,
        route: 'export-reported-posts',
        name: 'Reported posts',
      },
      {
        order: 6,
        route: 'export-interactions',
        name: 'Interactions',
      },
    ],
    service: 'BONOBO',
    setting: 'HAS_SOCIAL_REPORTS',
  },
  {
    order: 6,
    name: 'User entry',
    icon: FormIcon,
    route: 'reporting-user-entry',
    items: [
      {
        order: 1,
        route: 'export-triplee-diaries',
        name: 'Diaries',
      },
      {
        order: 2,
        route: 'export-triplee-log-access',
        name: 'Log access',
      },
      {
        order: 3,
        route: 'export-triplee-user-goals',
        name: 'User goals',
      },
      
    ],
    service: 'TRIPLE_E',
  },
  {
    order: 7,
    name: 'Users',
    icon: PartnersIcon,
    route: 'reporting-users',
    items: [
      {
        order: 1,
        route: 'export-custom-events',
        name: 'Custom events',
        setting: 'HAS_CUSTOM_EVENTS_REPORT',
      },
      {
        order: 2,
        route: 'export-groups',
        name: 'Groups',
        setting: 'HAS_GROUPS_REPORT',
      },
      {
        order: 3,
        route: 'export-groups-contacts',
        name: 'Groups contacts',
        setting: 'HAS_GROUP_CONTACTS_REPORT',
      },
      {
        order: 4,
        route: 'export-logins',
        name: 'Logins',
        setting: 'HAS_LOGIN_REPORT',
      },
      {
        order: 5,
        route: 'export-logins-trials',
        name: 'Login trials',
        projectId: OURFUTURES_PROJECT_ID,
        setting: 'HAS_CUSTOM_REPORT',
      },
      {
        order: 6,
        route: 'export-users',
        name: 'Profile information',
        setting: 'HAS_PROFILE_INFORMATION_REPORT',
      },
      {
        order: 7,
        route: 'export-mobile-app-access',
        name: 'Mobile app access',
        setting: 'HAS_MOBILE_APP_ACCESS_REPORT',
      },
      {
        order: 8,
        route: 'export-sessions',
        name: 'Sessions',
        setting: 'HAS_SESSION_REPORT',
      },
    ],
  },
  {
    order: 8,
    name: 'Custom report',
    icon: CustomFieldsIcon,
    route: 'reporting-custom-report',
    items: [],
    setting: 'HAS_CUSTOM_REPORT',
  },
];


export { NetfrontServices, REPORT_OPTIONS };