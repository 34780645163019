import { useHowlerMutation } from 'hooks';

import { UPDATE_PLACEHOLDER } from './useUpdatePlaceholder.graphql';
import {
  IUseUpdatePlaceholder,
  IUpdatePlaceholderMutationGraphQLResponse,
  IUseUpdatePlaceholderOptions,
  IUpdatePlaceholderMutationVariables,
  IHandleUpdatePlaceholderParams,
} from './useUpdatePlaceholder.interfaces';

const useUpdatePlaceholder = (options?: IUseUpdatePlaceholderOptions): IUseUpdatePlaceholder => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdatePlaceholderOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useHowlerMutation<
    IUpdatePlaceholderMutationGraphQLResponse,
    IUpdatePlaceholderMutationVariables
  >({
    mutation: mutation ?? UPDATE_PLACEHOLDER,
    options: {
      onCompleted: ({
        placeholder: {
          update: placeholder,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          placeholder,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdatePlaceholder = async ({
    id,
    key,
    value,
  }: IHandleUpdatePlaceholderParams) => {
    await executeCreateGroupInvitation({
      variables: {
        id,
        key,
        value,
      },
    });
  };

  return {
    handleUpdatePlaceholder,
    isLoading,
  };
};

export { useUpdatePlaceholder };
