import { gql } from '@apollo/client';

const SEARCH_PAGINATED_PLACEHOLDERS_QUERY = gql`
  query SearchPaginatedPaginatedPlaceholders(
    $projectId: String!
    $filter: String
    $first: Int
    $after: String
  ) {
    placeholder {
      list(
        projectId: $projectId
        filter: $filter
        first: $first
        after: $after
      ) {
        totalCount
        edges {
          node {
            id
            key
            value
          }
        }
      }
    }
  }
`;


export { SEARCH_PAGINATED_PLACEHOLDERS_QUERY };
