import { ReactNode, useContext, useEffect, useState } from 'react';


import { IGeladaOrganisation, useBackofficeSiteUrls, useDomain, useGetGeladaOrganisationByKey } from '@netfront/gelada-identity-library';
import { InvitationsIcon, NavigationItemProps, PartnersIcon, Spinner } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import Link from 'next/link';
import { useRouter } from 'next/router';

import DashboardContext from './DashboardContext';
import { getEkardoNavigationItems, getProductNavigationItems } from './DashboardContext.helpers';
import { DashboardContextProps } from './DashboardContext.interfaces';

import { useToast } from '../../hooks';

export function DashboardProvider({ children }: Readonly<DashboardContextProps>) {

  const { project, dashboardUrl, product, contentGroupTypes, isProduct, projectSettings } = useContext(CachingEntitiesContext);


  const { isDomainReady } = useDomain();
  const {
    query: { projectId: queryProjectId, organisationKey: queryOrganisationKey },
  } = useRouter();
  const { handleToastError } = useToast();

  const { getDashboardUrl: getBackOfficeUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });


  const [organisationNavigationItems, setOrganisationNavigationItems] = useState<NavigationItemProps[]>([]); 
  const [productNavigationItems, setProductNavigationItems] = useState<NavigationItemProps[]>([]); 
  const [ekardoNavigationItems, setEkardoNavigationItems] = useState<NavigationItemProps[]>([]); 
  const [projectId, setProjectId] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [organisation, setOrganisation] = useState<IGeladaOrganisation>();


  const { handleGetGeladaOrganisationByKey, isLoading: isGetOrganisationLoading } = useGetGeladaOrganisationByKey({
    onCompleted: ({ geladaOrganisation }) => {
      setOrganisation(geladaOrganisation);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });
  

  const getNavigationItems = () => {
    return [];
  };


  useEffect(() => {
    if (productNavigationItems.length > 0 && ekardoNavigationItems.length > 0 ) return;
    if (!(project && product && isDomainReady && contentGroupTypes && dashboardUrl && projectSettings)) return;

    const { id, organisation: projectOrganisation, services = [], isCustomBuild } = project;

    const { key } = projectOrganisation;

    const [baseUrl] = dashboardUrl.split('/dashboard');

    const ekardoBackOfficeUrl = getBackOfficeUrl();
    const dashboardLink = `${dashboardUrl}/${key}/${id}`;


    setOrganisationNavigationItems([
      {
        icon: PartnersIcon,
        label: 'Users',
        id: 'users',
        wrapperComponent: ({ children: wrapperChildren }: { children: ReactNode }) => <Link href={`/account/${String(key)}/team`}>{wrapperChildren}</Link>,
      },
      {
        icon: InvitationsIcon,
        label: 'Invitations',
        id: 'invitations',
        wrapperComponent: ({ children: wrapperChildren }: { children: ReactNode }) => <Link href={`/account/${String(key)}/team/invitations`}>{wrapperChildren}</Link>,
      },
    ]);


    setEkardoNavigationItems(getEkardoNavigationItems({
      backOfficeBaseUrl: `${ekardoBackOfficeUrl}/${key}/${projectId}`,
      baseUrl: dashboardLink,
      isCustomBuild,
      projectId: id,
      projectSettings,
      services,
    }));

    setProductNavigationItems(getProductNavigationItems({
      product,
      dashboardUrl: dashboardLink,
      projectId: id,
      baseUrl,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      hasGroupRequest: projectSettings?.['HAS_GROUP_REQUEST'] as boolean,
    }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, contentGroupTypes, projectSettings, isDomainReady, product, dashboardUrl]);


  useEffect(() => {
    if (project) return;
    if (!organisation) return;

    setOrganisationNavigationItems([
      {
        icon: PartnersIcon,
        label: 'Users',
        id: 'users',
        wrapperComponent: ({ children: wrapperChildren }: { children: ReactNode }) => <Link href={`/account/${String(organisation.key)}/team`}>{wrapperChildren}</Link>,
      },
      {
        icon: InvitationsIcon,
        label: 'Invitations',
        id: 'invitations',
        wrapperComponent: ({ children: wrapperChildren }: { children: ReactNode }) => <Link href={`/account/${String(organisation.key)}/team/invitations`}>{wrapperChildren}</Link>,
      },
    ])


  }, [organisation, project]);


  useEffect(() => {
    if (queryProjectId || project) return;
    if (!organisationKey) return;
    if (organisation && organisation.key === organisationKey) {
      return;
    }

    void handleGetGeladaOrganisationByKey({
      organisationKey: String(organisationKey),
      shouldIncludeOrganisationLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationKey, queryProjectId, project, organisation]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);


  return (
    <DashboardContext.Provider
      value={{
        organisationNavigationItems,
        navigationItems: isProduct ? productNavigationItems: ekardoNavigationItems,
        getNavigationItems,
      }}
    >
      {isGetOrganisationLoading ? <Spinner isLoading={true} /> : children}
    </DashboardContext.Provider>
  );
}
