import { IMembership } from '@netfront/gelada-identity-library';

import { getUserOrganisationAndProjectIds } from './getUserOrganisationAndProjectIds';

const getSingularOrgProjectRedirectUrl = (memberships: IMembership[]): string | null => {
  const { organizationKeys, projectKeys = [] } = getUserOrganisationAndProjectIds(memberships);

  if (organizationKeys.length === 1 && projectKeys.length === 1) return `${organizationKeys[0]}/${String(projectKeys[0])}`;
  return null;
};

export { getSingularOrgProjectRedirectUrl };
