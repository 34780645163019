import { useEffect, useState } from 'react';

import { ICookieOptionalAttributesInput, isSecureCookie, useCookie } from '@netfront/common-library';
import { DEFAULT_STORAGE_EXPIRY_OPTION, useDomain } from '@netfront/gelada-identity-library';

const useLastVisitedOrganisationCookie = () => {
  const { createLastOrganisationVisitedCookie, getLastOrganisationVisitedCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();

  const [domain, setDomain] = useState<string | null>();
  const [isSecure, setIsSecure] = useState(false);
  const [storedLastVisitedOrganisationCookie, setStoredLastVisitedOrganisationCookie] = useState<{ [key: string]: string } | undefined>();

  const optionalCookieAttributesInput: ICookieOptionalAttributesInput = {
    domain: domain ?? '',
    secure: isSecure,
    expiryOptions: {
      storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
    }
  };

  const updateLastVisitedOrganisationCookie = (value: { [key: string]: string }) => {
    createLastOrganisationVisitedCookie({
      optionalCookieAttributesInput,
      value: JSON.stringify({
        ...storedLastVisitedOrganisationCookie,
        ...value,
      }),
    });
  };

  const removeUserLastVisitedOrganisation = (userId?: string) => {
    if (!userId) return;

    const cookieToUpdate = Object.assign(storedLastVisitedOrganisationCookie ?? {}, {});
    delete cookieToUpdate[userId];
    createLastOrganisationVisitedCookie({
      optionalCookieAttributesInput,
      value: JSON.stringify(cookieToUpdate),
    });
  };

  const getLastVisitedOrganisationByUserId = (userId: string): string | undefined => {
    if (!storedLastVisitedOrganisationCookie || !userId) return undefined;
    return storedLastVisitedOrganisationCookie[userId];
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());
    setIsSecure(isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE));

    const lastVisitedOrganisationCookie: { [key: string]: string } = JSON.parse(getLastOrganisationVisitedCookie() ?? '{}');
    setStoredLastVisitedOrganisationCookie(lastVisitedOrganisationCookie);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return {
    updateLastVisitedOrganisationCookie,
    removeUserLastVisitedOrganisation,
    getLastVisitedOrganisationByUserId,
  };
};

export { useLastVisitedOrganisationCookie };
