import { useHowlerMutation } from 'hooks';

import { DELETE_PLACEHOLDER } from './useDeletePlaceholder.graphql';
import {
  IUseDeletePlaceholder,
  IDeletePlaceholderMutationGraphQLResponse,
  IUseDeletePlaceholderOptions,
  IDeletePlaceholderMutationVariables,
  IHandleDeletePlaceholderParams,
} from './useDeletePlaceholder.interfaces';

const useDeletePlaceholder = (options?: IUseDeletePlaceholderOptions): IUseDeletePlaceholder => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseDeletePlaceholderOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useHowlerMutation<
    IDeletePlaceholderMutationGraphQLResponse,
    IDeletePlaceholderMutationVariables
  >({
    mutation: mutation ?? DELETE_PLACEHOLDER,
    options: {
      onCompleted: ({
        placeholder: {
          remove: isCompleted,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleDeletePlaceholder = async ({
    placeholderId,
  }: IHandleDeletePlaceholderParams) => {
    await executeCreateGroupInvitation({
      variables: {
        placeholderId,
      },
    });
  };

  return {
    handleDeletePlaceholder,
    isLoading,
  };
};

export { useDeletePlaceholder };
