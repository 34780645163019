import { gql } from '@apollo/client';

export const UPDATE_PLACEHOLDER = gql`
  mutation updatePlaceholder(
    $id: Int!,
    $key: String!,
    $value: String!,
  ) {
    placeholder {
      update(
        id: $id,
        key: $key,
        value: $value,
      ) {
        id 
        key
        value
      }
    }
  }
`;

