import { ApolloError } from '@apollo/client';
import { useToast } from '@netfront/ekardo-content-library';
import {  useCreatePlaceholder, useUpdatePlaceholder } from 'hooks';
import { IPlaceholderOption } from 'types';


const useUpsertPlaceholder = ({ onUpdate }: { onUpdate: ({ placeholder }: { placeholder: IPlaceholderOption}) => void }) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleCreatePlaceholder, isLoading: isCreatePlaceholderLoading = false } = useCreatePlaceholder({
    onCompleted: ({ placeholder }) => {
      handleToastSuccess({
        message: 'Placeholder successfully updated',
      });
      onUpdate({ placeholder });
    },
    onError: handleGetError,
  });


  const { handleUpdatePlaceholder, isLoading: isUpdatePlaceholderLoading = false } = useUpdatePlaceholder({
    onCompleted: ({ placeholder }) => {
      handleToastSuccess({
        message: 'Placeholder successfully updated',
      });
      onUpdate({ placeholder });
    },
    onError: handleGetError,
  });

  const handleUpsertPlaceholder = ({ 
    placeholderId, 
    projectId, 
    key, 
    value,

  }: {
    key: string;
    placeholderId?: number;
    projectId: string;
    value: string;
  }) => {
    
    

    if (!placeholderId) {
      void handleCreatePlaceholder({
        projectId,
        key,
        value,
      })
    } else {

      void handleUpdatePlaceholder({
        id: placeholderId,
        key,
        value,
      });
    }
  };

  return {
    isLoading: 
      isCreatePlaceholderLoading || 
      isUpdatePlaceholderLoading,
    handleUpsertPlaceholder,
  };
};
export { useUpsertPlaceholder };
