import { IDBAsset } from '@netfront/common-library';

import { pushImageToAws } from './pushImageToAws';

const handleImageUpload = async ({callBack, logo, uploadedFile }: {callBack:  () => void; logo?: IDBAsset; uploadedFile?: File, }) => {
  if (logo && uploadedFile) {
    const { presignedPutUrl } = logo;
    await pushImageToAws(
      String(presignedPutUrl),
      uploadedFile,
      () => callBack(),
    );
  }
  return;
}
export { handleImageUpload };
