import { IMembership } from '@netfront/gelada-identity-library';

const getUserOrganisationAndProjectIds = (memberships: IMembership[]) => {
  const organizationKeys = [...new Set(memberships.map((item) => item.organisation.key))];
  const projectKeys = [...new Set(memberships.filter((item) => Boolean(item.project)).map((item) => item.project?.id))];

  return {
    organizationKeys,
    projectKeys,
  }
};

export { getUserOrganisationAndProjectIds };
