/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  getProjectDomainCookie,
  NetfrontProductType,
  removeItemFromLocalStorage,
  saveItemWithExpiryToLocalStorage,
} from '@netfront/common-library';
import { IGeladaProject, ISetting, LOCALHOST, PROTOCOLS, useDomain, useGetGeladaProject, useGetProduct, useGetSettings, getFormattedProjectSettings, IProjectSetting } from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import CachingEntitiesContext from './CachingEntitiesContext';
import { CachingEntitiesContextProps } from './CachingEntitiesContext.interfaces';

import { useGetContentGroupTypes, useToast } from '../../hooks';

export function CachingEntitiesProvider({ children }: Readonly<CachingEntitiesContextProps>) {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const { HTTP, HTTPS } = PROTOCOLS;

  const { getDomain, isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [canImpersonateUser, setCanImpersonateUser] = useState<boolean>(false);
  const [dashboardUrl, setDashboardUrl] = useState<string>();
  const [externalUrl, setExternalUrl] = useState<string>();
  const [hasGroups, setHasGroups] = useState<boolean>(false);
  const [hasUnits, setHasUnits] = useState<boolean>(false);
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [productName, setProductName] = useState<NetfrontProductType>();
  const [project, setProject] = useState<IGeladaProject>();
  const [projectExpiryStored, setProjectExpiryStored] = useState<number>();
  const [projectId, setProjectId] = useState<string>('');
  const [contentGroupTypes, setContentGroupTypes] = useState<string[]>();
  const [contentGroupTypesExpiry, setContentGroupTypesExpiry] = useState<number>();
  const [projectSettings, setProjectSettings] = useState<IProjectSetting>();
  const [projectSettingsExpiry, setProjectSettingsExpiry] = useState<number>();

  const getBaselineUrl = (projectDomain: string) => {
    if (getDomain() === LOCALHOST) {
      const product = getProduct();

      const portSuffix = `:${
        product === 'KANZI' ? String(process.env.REACT_APP_KANZI_LOCAL_PORT) : String(process.env.REACT_APP_QUICKCODES_LOCAL_PORT)
      }`;

      return `${HTTP}${LOCALHOST}${portSuffix}`;
    }

    const environmentPrefix = environment ? `${environment}.` : '';

    return `${HTTPS}${environmentPrefix}${projectDomain}`;
  };

  const getAdminBaselineUrl = (projectDomain: string) => {
    if (getDomain() === LOCALHOST) {
      const portSuffix = `:${String(process.env.REACT_APP_ADMIN_LOCAL_PORT)}`;

      return `${HTTP}${LOCALHOST}${portSuffix}`;
    }

    const environmentPrefix = environment ? `${environment}.` : '';

    return `${HTTPS}${environmentPrefix}admin.${projectDomain}`;
  };

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject }) => {
      saveItemWithExpiryToLocalStorage('project', JSON.stringify(geladaProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setProject(geladaProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetContentGroupTypes, isLoading: isGetContentGroupTypesLoading } = useGetContentGroupTypes({
    fetchPolicy: 'cache-first',
    onCompleted: ({ contentGroupTypes: returnedContentGroupTypes }) => {
      saveItemWithExpiryToLocalStorage('contentGroupTypes', JSON.stringify(returnedContentGroupTypes), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setContentGroupTypes(returnedContentGroupTypes);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleGetSettings, isLoading: isGetSettingsLoading = false } = useGetSettings({
    fetchPolicy: 'no-cache',
    onCompleted: ({ settings: returnedSettings }) => {
      saveItemWithExpiryToLocalStorage('projectSettings', JSON.stringify(returnedSettings), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      const formattedSettings = getFormattedProjectSettings(returnedSettings as ISetting[]);

      setProjectSettings(formattedSettings as IProjectSetting);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const refreshProjectDetails = () => {
    const product = getProduct();
    const isEkardoProject = product === 'EKARDO';
    const projectDomain = String(getProjectDomainCookie());
    const baselineUrl = String(isEkardoProject ? getAdminBaselineUrl(projectDomain) : getBaselineUrl(projectDomain));

    setExternalUrl(baselineUrl);
    setDashboardUrl(`${baselineUrl}/dashboard`);
    setProductName(product);
    setHasGroups(isEkardoProject);
    setCanImpersonateUser(isEkardoProject);
    setHasUnits(isEkardoProject);
    setIsProduct(!isEkardoProject);
  };

  useEffect(() => {
    if (!projectId) {
      removeItemFromLocalStorage('project');
      removeItemFromLocalStorage('contentGroupTypes');
      removeItemFromLocalStorage('projectSettings');
      setProject(undefined);
      return;
    }

    if (projectId !== project?.id) {
      removeItemFromLocalStorage('project');
      removeItemFromLocalStorage('contentGroupTypes');
      removeItemFromLocalStorage('projectSettings');

      void handleGetGeladaProject({
        projectId: String(projectId),
        shouldIncludeProjectLogo: true,
        shouldIncludeProjectSettings: true,
      });

      void handleGetContentGroupTypes({
        projectId: String(projectId),
      });

      handleGetSettings({
        projectId: String(projectId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project]);

  useEffect(() => {
    const projectStoredJSON = JSON.parse(String(window.localStorage.getItem('project')));

    if (!projectStoredJSON) {
      return;
    }

    setProject(JSON.parse(String(projectStoredJSON.value)) as IGeladaProject);
    setProjectExpiryStored(Number(projectStoredJSON.expiry));
  }, []);

  useEffect(() => {
    const contentGroupTypesJson = JSON.parse(String(window.localStorage.getItem('contentGroupTypes')));

    if (!contentGroupTypesJson) {
      return;
    }

    setContentGroupTypes(JSON.parse(String(contentGroupTypesJson.value)) as string[]);
    setContentGroupTypesExpiry(Number(contentGroupTypesJson.expiry));
  }, []);

  useEffect(() => {
    if (!projectId) return;
    const projectSettingsJSON = JSON.parse(String(window.localStorage.getItem('projectSettings')));

    if (!projectSettingsJSON) {
      return;
    }
    const formattedSettings = getFormattedProjectSettings(JSON.parse(String(projectSettingsJSON.value)) as ISetting[])

    setProjectSettings(formattedSettings as IProjectSetting);
    setProjectSettingsExpiry(Number(projectSettingsJSON.expiry));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!projectSettingsExpiry) {
      return;
    }

    if (new Date().getTime() < projectSettingsExpiry) {
      return;
    }

    removeItemFromLocalStorage('projectSettings');
  }, [projectSettingsExpiry]);

  useEffect(() => {
    if (!projectExpiryStored) {
      return;
    }

    if (new Date().getTime() < projectExpiryStored) {
      return;
    }

    removeItemFromLocalStorage('project');
  }, [projectExpiryStored]);

  useEffect(() => {
    if (!contentGroupTypesExpiry) {
      return;
    }

    if (new Date().getTime() < contentGroupTypesExpiry) {
      return;
    }

    removeItemFromLocalStorage('contentGroupTypes');
  }, [contentGroupTypesExpiry]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    

    const product = getProduct();
    const isEkardoProject = product === 'EKARDO';
    const projectDomain = String(getProjectDomainCookie());
    const baselineUrl = String(isEkardoProject ? getAdminBaselineUrl(projectDomain) : getBaselineUrl(projectDomain));

    setExternalUrl(baselineUrl);
    setDashboardUrl(`${baselineUrl}/dashboard`);
    setProductName(product);
    setHasGroups(isEkardoProject);
    setCanImpersonateUser(isEkardoProject);
    setHasUnits(isEkardoProject);
    setIsProduct(!isEkardoProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, getProduct]);
  

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <CachingEntitiesContext.Provider
      value={{
        contentGroupTypes,
        project,
        dashboardUrl,
        isProduct,
        externalUrl,
        product: productName,
        hasGroups,
        canImpersonateUser,
        hasUnits,
        refreshProjectDetails,
        projectSettings,
      }}
    >
      {isGetGeladaProjectLoading || isGetContentGroupTypesLoading || isGetSettingsLoading ? <Spinner isLoading={true} /> : children}
    </CachingEntitiesContext.Provider>
  );
}
