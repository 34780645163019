import { createContext } from 'react';

import { NetfrontProductType } from '@netfront/common-library';
import { IGeladaProject, IProjectSetting } from '@netfront/gelada-identity-library';
export interface ICachingEntitiesContext {
  canImpersonateUser: boolean;
  contentGroupTypes: string[] | undefined;
  dashboardUrl: string | undefined;
  externalUrl?: string;
  hasGroups: boolean;
  hasUnits: boolean;
  isProduct: boolean;
  product?: NetfrontProductType;
  project: IGeladaProject | undefined;
  projectSettings: IProjectSetting | undefined;
  refreshProjectDetails: () => void;
}

export const CachingEntitiesContext = createContext<ICachingEntitiesContext>({
  project: undefined,
  contentGroupTypes: undefined,
  isProduct: false,
  dashboardUrl: undefined,
  externalUrl: undefined,
  product: 'EKARDO',
  hasGroups: false,
  canImpersonateUser: false,
  hasUnits: false,
  refreshProjectDetails: () => {
    return;
  },
  projectSettings: undefined,
});

// eslint-disable-next-line import/no-default-export
export default CachingEntitiesContext;
