import { useHowlerMutation } from 'hooks';

import { CREATE_PLACEHOLDER } from './useCreatePlaceholder.graphql';
import {
  IUseCreatePlaceholder,
  ICreatePlaceholderMutationGraphQLResponse,
  IUseCreatePlaceholderOptions,
  ICreatePlaceholderMutationVariables,
  IHandleCreatePlaceholderParams,
} from './useCreatePlaceholder.interfaces';

const useCreatePlaceholder = (options?: IUseCreatePlaceholderOptions): IUseCreatePlaceholder => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreatePlaceholderOptions);

  const [executeCreateGroupInvitation, { loading: isLoading }] = useHowlerMutation<
    ICreatePlaceholderMutationGraphQLResponse,
    ICreatePlaceholderMutationVariables
  >({
    mutation: mutation ?? CREATE_PLACEHOLDER,
    options: {
      onCompleted: ({
        placeholder: {
          add: placeholder,
        },
      }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          placeholder,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreatePlaceholder = async ({
    projectId,
    key,
    value,
  }: IHandleCreatePlaceholderParams) => {
    await executeCreateGroupInvitation({
      variables: {
        projectId,
        key,
        value,
      },
    });
  };

  return {
    handleCreatePlaceholder,
    isLoading,
  };
};

export { useCreatePlaceholder };
